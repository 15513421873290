.container {
  background: linear-gradient(to right, #3cad3d, #83c13f);
  font-weight: 600;
  filter: drop-shadow(0 8px 8px rgba(0, 0, 0, 0.128));
  border-radius: 15px;
  padding-left: 40px;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  background-color: white;
  width: 100%;
  color: white;
  height: fit-content;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.title {
  font-size: 30px;
  text-shadow: 1px 1px 3px rgba(165, 163, 163, 0.358); 
}

.paragraph {
  font-size: 16px;
  text-shadow: 1px 1px 2px rgba(96, 96, 96, 0.291); 
}
.marketingLink {
  font-size: 16px;
  text-shadow: 1px 1px 2px rgba(96, 96, 96, 0.291);
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  width: fit-content;
  cursor: pointer;
}

@media (max-width: 1350px) {
  .container {
    height: fit-content;
    padding-bottom: 20px;
  }
}